import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LocationPart } from "../components/CartographyCrafter/components/Location/LocationPart";
import {
  defaultBorderPaddingScalar,
  defaultBorderRadius,
  defaultFontSizeScalar,
} from "../components/CartographyCrafter/components/OutputMapFeatures/FeatureBounds";
import { maxImageLength } from "../components/CartographyCrafter/components/Steps/MapStep/ImageSizeConstants";
import { ThemeIcon } from "../components/CartographyCrafter/components/ThemeIcon/ThemeIcon";

const defaultZoom = 11;

interface MapState {
  width: number;
  rawWidth: string;
  height: number;
  rawHeight: string;
  zoom: number;
  rawZoom: string;
  borderRadius: number;
  showCoordinates: boolean;
  dominantColorsLeftToRight: boolean;
  showDominantColors: boolean;
  fontScalar: number;
  borderPaddingScalar: number;
  mapTheme: string;
  fontFamily: string;
  storedLocationParts: LocationPart[];
  latitude: number;
  longitude: number;
  coordinateFormat: string;
}

const initialState: MapState = {
  width: maxImageLength,
  rawWidth: String(maxImageLength),
  height: maxImageLength,
  rawHeight: String(maxImageLength),
  zoom: defaultZoom,
  rawZoom: String(defaultZoom),
  borderRadius: defaultBorderRadius,
  showCoordinates: true,
  showDominantColors: true,
  fontScalar: defaultFontSizeScalar,
  borderPaddingScalar: defaultBorderPaddingScalar,
  dominantColorsLeftToRight: true,
  mapTheme: ThemeIcon.Grayscale,
  fontFamily: "Oswald",
  storedLocationParts: [],
  latitude: 0,
  longitude: 0,
  coordinateFormat: "Lat/Long",
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setWidth: (state, action: PayloadAction<number>) => {
      state.width = action.payload;
    },
    setRawWidth: (state, action: PayloadAction<string>) => {
      state.rawWidth = action.payload;
    },
    setHeight: (state, action: PayloadAction<number>) => {
      state.height = action.payload;
    },
    setRawHeight: (state, action: PayloadAction<string>) => {
      state.rawHeight = action.payload;
    },
    setZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
    setRawZoom: (state, action: PayloadAction<string>) => {
      state.rawZoom = action.payload;
    },
    setBorderRadius: (state, action: PayloadAction<number>) => {
      state.borderRadius = action.payload;
    },
    setShowCoordinates: (state, action: PayloadAction<boolean>) => {
      state.showCoordinates = action.payload;
    },
    setShowDominantColors: (state, action: PayloadAction<boolean>) => {
      state.showDominantColors = action.payload;
    },
    setDominantColorsLeftToRight: (state, action: PayloadAction<boolean>) => {
      state.dominantColorsLeftToRight = action.payload;
    },
    setFontScalar: (state, action: PayloadAction<number>) => {
      state.fontScalar = action.payload;
    },
    setBorderPaddingScalar: (state, action: PayloadAction<number>) => {
      state.borderPaddingScalar = action.payload;
    },
    setMapTheme: (state, action: PayloadAction<string>) => {
      state.mapTheme = action.payload;
    },
    setFontFamily: (state, action: PayloadAction<string>) => {
      state.fontFamily = action.payload;
    },
    setStoredLocationParts: (state, action: PayloadAction<LocationPart[]>) => {
      state.storedLocationParts = action.payload;
    },
    setLatitude: (state, action: PayloadAction<number>) => {
      state.latitude = action.payload;
    },
    setLongitude: (state, action: PayloadAction<number>) => {
      state.longitude = action.payload;
    },
    setCoordinateFormat: (state, action: PayloadAction<string>) => {
      state.coordinateFormat = action.payload;
    },
    resetMapSlice: () => initialState,
  },
});

export const {
  setWidth,
  setRawWidth,
  setHeight,
  setRawHeight,
  setZoom,
  setRawZoom,
  setBorderRadius,
  setShowCoordinates,
  setShowDominantColors,
  setFontScalar,
  setBorderPaddingScalar,
  setMapTheme,
  resetMapSlice,
  setFontFamily,
  setStoredLocationParts,
  setLatitude,
  setLongitude,
  setCoordinateFormat,
  setDominantColorsLeftToRight,
} = mapSlice.actions;
export default mapSlice.reducer;

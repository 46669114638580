import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  defaultBorderPaddingScalar,
  defaultBorderRadius,
  defaultFontSizeScalar,
} from "../components/CartographyCrafter/components/OutputMapFeatures/FeatureBounds";
import { DEFAULT_RELEASE_DATE_FORMAT } from "../components/TunePosters/Components/Steps/FeaturesStep/TuneMarker";

interface TuneFeatureState {
  fontScalar: number;
  borderPaddingScalar: number;
  borderRadius: number;
  tuneFontFamily: string;
  releaseDateFormat: string;
  showDominantColors: boolean;
  dominantColorsLeftToRight: boolean;
  showSongLengths: boolean;
  showAlbumLength: boolean;
  showTrackNumbers: boolean;
  dominantColorsValue: number;
  metaDataSongsPadding: number;
  customAlbumName: string;
}

const initialState: TuneFeatureState = {
  fontScalar: defaultFontSizeScalar,
  borderPaddingScalar: defaultBorderPaddingScalar,
  borderRadius: defaultBorderRadius,
  tuneFontFamily: "Raleway",
  releaseDateFormat: DEFAULT_RELEASE_DATE_FORMAT,
  showDominantColors: true,
  dominantColorsLeftToRight: true,
  showSongLengths: true,
  showAlbumLength: true,
  showTrackNumbers: true,
  dominantColorsValue: 80,
  metaDataSongsPadding: 50,
  customAlbumName: "",
};

export const tuneFeatureSlice = createSlice({
  name: "tuneFeatures",
  initialState,
  reducers: {
    setFontScalar: (state, action: PayloadAction<number>) => {
      state.fontScalar = action.payload;
    },
    setBorderPaddingScalar: (state, action: PayloadAction<number>) => {
      state.borderPaddingScalar = action.payload;
    },
    setBorderRadius: (state, action: PayloadAction<number>) => {
      state.borderRadius = action.payload;
    },
    setTuneFontFamily: (state, action: PayloadAction<string>) => {
      state.tuneFontFamily = action.payload;
    },
    setReleaseDateFormat: (state, action: PayloadAction<string>) => {
      state.releaseDateFormat = action.payload;
    },
    setShowDominantColors: (state, action: PayloadAction<boolean>) => {
      state.showDominantColors = action.payload;
    },
    setDominantColorsLeftToRight: (state, action: PayloadAction<boolean>) => {
      state.dominantColorsLeftToRight = action.payload;
    },
    setShowSongLengths: (state, action: PayloadAction<boolean>) => {
      state.showSongLengths = action.payload;
    },
    setShowAlbumlength: (state, action: PayloadAction<boolean>) => {
      state.showAlbumLength = action.payload;
    },
    setShowTrackNumbers: (state, action: PayloadAction<boolean>) => {
      state.showTrackNumbers = action.payload;
    },
    setDominantColorsValue: (state, action: PayloadAction<number>) => {
      state.dominantColorsValue = action.payload;
    },
    setMetaDataSongsPadding: (state, action: PayloadAction<number>) => {
      state.metaDataSongsPadding = action.payload;
    },
    setCustomAlbumName: (state, action: PayloadAction<string>) => {
      state.customAlbumName = action.payload;
    },
    resetTuneFeatureSlice: () => initialState,
  },
});

export const {
  setFontScalar,
  setBorderPaddingScalar,
  setBorderRadius,
  setTuneFontFamily,
  setReleaseDateFormat,
  setShowDominantColors,
  setShowSongLengths,
  setShowAlbumlength,
  setShowTrackNumbers,
  resetTuneFeatureSlice,
  setDominantColorsValue,
  setMetaDataSongsPadding,
  setCustomAlbumName,
  setDominantColorsLeftToRight,
} = tuneFeatureSlice.actions;
export default tuneFeatureSlice.reducer;

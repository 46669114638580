import { offBlack, offWhite } from "../../../../shared/Constants/Colors";
import { sortHexColorsByLuminance } from "../color/ColorHelpers";
import CanvasAndContext from "./CanvasAndContext";
import { getTopColors } from "./ImageHelpers";

const DEFAULT_BORDER_PADDING_SCALER = 10;
const DEFAULT_TEXT_PADDING_SCALAR = 2;
const DEFAULT_FONT_SCALAR = 0.06;
const DEFAULT_TOP_COLORS = 5;
const DEFAULT_COLOR_BLOCK_SCALAR = 30;
const DEFAULT_LOCATION_FONT = "Oswald";
const DEFAULT_COORDINATE_FONT = "Oswald";
const DEFAULT_IMAGE_COLOR_BLOCK_GAP = 25;
const DEFAULT_COLOR_GAP = 15;
const DEFAULT_COORDINATE_COLOR = "#6F6F6F";
const DEFAULT_LOCATION_PARTS_COLOR = offBlack;
const DEFAULT_BORDER_RADIUS = 0;

export class MapMarker {
  private locationImage: string;
  private locationParts: string[];
  private coordinates?: string;
  private showDominantColors: boolean;
  private borderPaddingScalar: number;
  private textPaddingScalar: number;
  private fontScalar: number;
  private backgroundColor: string;
  private numberOfTopColors: number;
  private coordinateColor: string;
  private locationPartsColor: string;
  private borderRadius: number;
  private locationFont: string;
  private dominantColorsThreshold: number;
  private dominantColorsLeftToRight: boolean;
  private additionalPaddingBelowImage: number;

  private image?: HTMLImageElement;

  private additionalDrawMethods: ((
    canvasAndContext: CanvasAndContext
  ) => void)[];

  constructor(locationImage: string) {
    this.locationImage = locationImage;
    this.locationParts = [];
    this.showDominantColors = true;
    this.borderPaddingScalar = DEFAULT_BORDER_PADDING_SCALER;
    this.textPaddingScalar = DEFAULT_TEXT_PADDING_SCALAR;
    this.fontScalar = DEFAULT_FONT_SCALAR;
    this.backgroundColor = offWhite;
    this.numberOfTopColors = DEFAULT_TOP_COLORS;
    this.coordinateColor = DEFAULT_COORDINATE_COLOR;
    this.locationPartsColor = DEFAULT_LOCATION_PARTS_COLOR;
    this.borderRadius = DEFAULT_BORDER_RADIUS;
    this.locationFont = DEFAULT_LOCATION_FONT;
    this.additionalDrawMethods = [];
    this.dominantColorsThreshold = 80;
    this.dominantColorsLeftToRight = true;
    this.additionalPaddingBelowImage = 0;
  }

  addAdditionalDrawMethod(
    drawMethod: (canvasAndContext: CanvasAndContext) => void
  ) {
    this.additionalDrawMethods.push(drawMethod);
  }

  setAdditionalPaddingBelowImage(
    additionalPaddingBelowImage: number
  ): MapMarker {
    this.additionalPaddingBelowImage = additionalPaddingBelowImage;
    return this;
  }

  setDominantColorThreshold(dominantColorThreshold: number): MapMarker {
    this.dominantColorsThreshold = dominantColorThreshold;
    return this;
  }

  setBorderRadius(borderRadius: number): MapMarker {
    this.borderRadius = borderRadius;
    return this;
  }

  setCoordinates(coordinates: string): MapMarker {
    this.coordinates = coordinates;
    return this;
  }

  setLocationParts(locationParts: string[]): MapMarker {
    this.locationParts = locationParts;
    return this;
  }

  setShowDominantColors(showDominantColors: boolean): MapMarker {
    this.showDominantColors = showDominantColors;
    return this;
  }

  setDominantColorsLeftToRight(dominantColorsLeftToRight: boolean): MapMarker {
    this.dominantColorsLeftToRight = dominantColorsLeftToRight;
    return this;
  }

  setBorderPaddingScalar(borderPaddingScalar: number): MapMarker {
    this.borderPaddingScalar = borderPaddingScalar;
    return this;
  }

  setNumberOfTopColors(numberOfTopColors: number): MapMarker {
    this.numberOfTopColors = numberOfTopColors;
    return this;
  }

  setTextPaddingScalar(textPaddingScalar: number): MapMarker {
    this.textPaddingScalar = textPaddingScalar;
    return this;
  }

  setFontScalar(fontScalar: number): MapMarker {
    this.fontScalar = fontScalar;
    return this;
  }

  setLocationFont(fontFamily: string): MapMarker {
    this.locationFont = fontFamily;
    return this;
  }

  /**
   * Draws the dominant colors below the image.
   *
   * @param image the image
   * @param canvasAndContext the canvas and context
   * @returns the amount to increment the current Y tracking variable by
   */
  private async drawDominantColors(
    image: HTMLImageElement,
    canvasAndContext: CanvasAndContext
  ): Promise<number> {
    const topColors: string[] = await getTopColors(
      this.locationImage,
      this.numberOfTopColors,
      this.dominantColorsThreshold
    );
    const luminancedColor = sortHexColorsByLuminance(topColors);
    if (!this.dominantColorsLeftToRight) {
      luminancedColor.reverse();
    }

    const totalPadding = DEFAULT_COLOR_GAP * (luminancedColor.length - 1);
    const availableWidth = image.width - totalPadding;
    const rectangleWidth = availableWidth / luminancedColor.length;

    const context = canvasAndContext.getContext();
    const borderPadding = image.width / this.borderPaddingScalar;
    const rectangleHeight = this.getDominantColorRectangleHeight();
    let currentX = borderPadding;
    luminancedColor.forEach((color: string) => {
      context.fillStyle = color;
      context.fillRect(
        currentX,
        image.height + borderPadding + DEFAULT_IMAGE_COLOR_BLOCK_GAP,
        rectangleWidth,
        rectangleHeight
      );

      currentX += rectangleWidth + DEFAULT_COLOR_GAP;
    });

    return rectangleHeight + DEFAULT_IMAGE_COLOR_BLOCK_GAP;
  }

  getDominantColorRectangleHeight() {
    return this.image!.height / DEFAULT_COLOR_BLOCK_SCALAR;
  }

  getBorderPadding() {
    return this.image!.width / this.borderPaddingScalar;
  }

  getFontSize() {
    return this.image!.width * this.fontScalar;
  }

  getTextPadding() {
    return this.getFontSize() * this.textPaddingScalar;
  }

  getAdditionalPaddingBelowImage() {
    return this.additionalPaddingBelowImage;
  }

  getDominantColorsBottom() {
    const colorHeight = this.showDominantColors
      ? this.getDominantColorRectangleHeight()
      : 0;

    return (
      this.image!.height +
      this.getBorderPadding() +
      DEFAULT_IMAGE_COLOR_BLOCK_GAP +
      colorHeight
    );
  }

  private setupAndGetCanvasAndContext() {
    const imageWidth = this.image!.width;
    const imageHeight = this.image!.height;

    const borderPadding = this.getBorderPadding();
    const fontSize = this.getFontSize();
    const textPadding = this.getTextPadding();

    const textHeight = fontSize * this.locationParts.length;
    const canvasWidth = imageWidth + borderPadding * 2;
    const canvasHeight =
      borderPadding + imageHeight + textPadding + textHeight + borderPadding;
    return new CanvasAndContext({
      width: canvasWidth,
      height: canvasHeight,
    });
  }

  private async drawImage(): Promise<string> {
    if (this.image === undefined) return "";

    const borderPadding = this.getBorderPadding();
    const fontSize = this.getFontSize();

    const canvasAndContext = this.setupAndGetCanvasAndContext();
    canvasAndContext.fillBackground(this.backgroundColor);
    canvasAndContext.drawRoundedImage(
      this.image,
      borderPadding,
      this.borderRadius
    );

    let currentY = borderPadding + this.image.height + this.getTextPadding();
    if (this.showDominantColors) {
      const incrementYBy = await this.drawDominantColors(
        this.image,
        canvasAndContext
      );
      currentY += incrementYBy;
    }

    canvasAndContext.setFont(fontSize, this.locationFont, true);
    canvasAndContext.setFillStyle(this.locationPartsColor);
    canvasAndContext.drawAlignedStrings(this.locationParts, fontSize, currentY);
    currentY += Math.max(fontSize * (this.locationParts.length - 1), 0);
    if (this.coordinates) {
      canvasAndContext.setFont(fontSize / 2, DEFAULT_COORDINATE_FONT);
      canvasAndContext.setFillStyle(this.coordinateColor);
      const yToDrawAt = currentY + fontSize;
      canvasAndContext.drawAlignedString(
        this.coordinates!,
        fontSize,
        yToDrawAt
      );
    }

    this.additionalDrawMethods.forEach((drawMethod) =>
      drawMethod(canvasAndContext)
    );

    return canvasAndContext.getBase64ImageString();
  }

  async draw(): Promise<string> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = this.locationImage;
      image.onload = async () => {
        this.image = image;
        resolve(this.drawImage());
      };
      image.onerror = (error) => reject(error);
    });
  }
}
